import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const ServiceSidebar = () => {
    const myPage = 'service-residential-details';
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <AnchorLink to={`/${myPage}#fixed-wireless`} title="Fixed Wireless">
                        <span>Fixed Wireless</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#FTTH`} title="Fiber To The Home">
                        <span>Fiber To The Home</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#ugo`} title="UGO MOBILE">
                        <span>UGO MOBILE</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#pricing-lagos`} title="Prices Lagos">
                        <span>Prices Lagos</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#pricing-abuja`} title="Prices Abuja">
                        <span>Prices Abuja</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#pricing-mobile`} title="Prices Mobile">
                        <span>Prices Mobile</span>
                    </AnchorLink>
                </li>

            </ul>

            <div className="services-contact-info">
                <h3>Coverage Areas</h3>
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Lagos</span>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Abuja</span>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Major Cities</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar