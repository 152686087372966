import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/service-residential.jpg'
import wireless from '../../../assets/images/services/service-residential-wireless.jpg'
import fiber from '../../../assets/images/services/service-residential-fiber.jpg'
import Pricing from './Pricing';

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3 id="fixed-wireless">Fixed Wireless</h3>
                            <p>
                                This technology engages a wireless connection between two nodes, or radio units, in a data network.
                                Each radio unit consists of a transceiver and a highly directive antenna, typically operating at
                                medium to high microwave frequencies.
                            </p>
                            <p>
                                This technology enables our residential users access unlimited movies, TV shows, and commercial free
                                programs, streamed instantly over the internet while enjoying efficient and quality internet
                                experience to browse the world.
                            </p>
                            <p>
                                This technology can also be utilized in small, medium or large enterprises to ensure unlimited access to
                                work teams and partners for smooth organizational processes.
                            </p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={wireless} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h4>The benefits of this technology include:</h4>
                                        <ul>
                                            <li>High broadband speeds</li>
                                            <li>Completely unlimited with no hidden quota cap </li>
                                            <li>24/7 technical support and interventions done within 24hours</li>
                                            <li>Fast, seamless, stable and reliable connection</li>
                                            <li>IPTV Services</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <h3 id="FTTH">Fiber To The Home</h3>
                            <p>
                                The FTTH services allow the running of fibre-optic cables straight to your location using
                                state-of-the art technology with enhanced dynamic configurations for improved security and
                                flexible management, which makes it possible to enjoy a faster surfing experience, anywhere
                                in your homes or SME’s.
                            </p>
                            <p>
                                There are various plans to choose from to suit each individual and corporate needs
                            </p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={fiber} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h4>The benefits of this technology include:</h4>
                                        <ul>
                                            <li>Fast, seamless, stable and reliable connection</li>
                                            <li>High broadband speeds </li>
                                            <li>Completely unlimited with no hidden quota cap</li>
                                            <li>24/7 technical support and interventions are done within 24hours.</li>
                                            <li>Online payments</li>
                                            <li>Affordable and cost-effective fibre plans in the market</li>
                                            <li>IPTV Services</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <h3 id="ugo">UGO MOBILE</h3>

                            <p>
                                UGO Service is based on a mobile broadband access technology in NLOS (non-line-of-sight) environments.
                                UGO modems have Wi-Fi in Built-in feature, Ethernet interface, support data and telephony services.
                            </p>
                            <p>
                                UGO offers simple installations (Plug and Play), Seamless Roaming, enabling users to carry the CPE
                                everywhere with a wide choice of plans.
                                Some UGO terminals are rugged for outdoor usage and provide very high speed.
                            </p>
                        </div>

                    </div>

                    <div className="col-lg-3 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <Pricing />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent